import React, { useState } from 'react';
import { css, cx } from 'emotion';
import newPolicyUrl from '../../assets/newPolicy.png';
import closeIcon from '../../assets/closeIcon.svg';
import { AXIS_AESTHETIC, AXIS_CLINICAL, BEAUTY, COVERMYTOOLS, schemeInformation } from '../../constants/products';

const containerStyle = css`
  border-radius: 8px;
  background: rgba(255, 255, 255, 0.5);
  min-width: 23.125rem;
  margin-bottom: 1.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 3rem;
  align-self: stretch;
  cursor: pointer;
  max-height: 11.75rem;
  @media (max-width: 767px) {
    min-width: 100%;
  }
  &:hover {
    box-shadow: 0 8px 40px rgba(64, 64, 66, 0.08);
  }
`;

const openContainerStyle = css`
  border-radius: 8px;
  background: white;
  min-width: 23.125rem;
  margin-bottom: 1.5rem;
  display: flex;
  flex-direction: column;
  padding: 0 1.5rem 1.5rem 1.5rem;
  align-self: stretch;
  cursor: pointer;
  max-height: 11.75rem;
  @media (max-width: 767px) {
    min-width: 100%;
  }
  &:hover {
    box-shadow: 0 8px 40px rgba(64, 64, 66, 0.08);
  }
`;

const textStyle = css`
  font-weight: bold;
  font-size: 1.125rem;
  line-height: 1.2em;
  text-align: center;
  letter-spacing: -0.03em;
  color: var(--darkGray);
`;

const imgStyle = css`
  height: 2rem;
  margin-bottom: 0.5rem;
`;

const closeIconStyle = css`
  height: 1rem;
  margin-top: 1.25rem;
`;

const headerContainer = css`
  display: flex;
  justify-content: flex-end;
`;

const rowStyle = css`
  display: flex;

  font-weight: bold;
  font-size: 1rem;
  line-height: 1.1875rem;
  letter-spacing: -0.03em;
  color: var(--dark);
  margin-bottom: 1rem;
  cursor: pointer;
  text-decoration: none;
  &:hover {
    text-decoration: none;
  }
  &:hover .linkTextStyle:after {
    opacity: 1;
    width: 100%;
  }
  &:visited {
    color: var(--dark);
  }
`;

const logoStyle = css`
  height: 1rem;
  margin-right: 0.75rem;
`;

const linkTextStyle = css`
  position: relative;
  &:after {
    content: '';
    position: absolute;
    bottom: -3px;
    height: 2px;
    left: 50%;
    width: 0;
    opacity: 0;
    background: var(--primaryBrand);
    transform: translateX(-50%);
    transition: width 0.25s, opacity 0.25s;
  }
`;

export const NewPolicyCard = ({ quote }) => {
  const [openNewPolicy, setOpenNewPolicy] = useState(false);

  return openNewPolicy ? (
    <div className={openContainerStyle}>
      <div className={headerContainer}>
        <img src={closeIcon} alt="close" className={closeIconStyle} onClick={() => setOpenNewPolicy(false)} />
      </div>
      <a className={rowStyle} href={schemeInformation[COVERMYTOOLS].url} target="_blank" rel="noopener noreferrer">
        <img src={schemeInformation[COVERMYTOOLS].logo} alt="cmt logo" className={logoStyle} />
        <div className={cx(linkTextStyle, 'linkTextStyle')}>{schemeInformation[COVERMYTOOLS].name}</div>
      </a>
      <a className={rowStyle} href={schemeInformation[BEAUTY].url} target="_blank" rel="noopener noreferrer">
        <img src={schemeInformation[BEAUTY].logo} alt="Insync logo" className={logoStyle} />
        <div className={cx(linkTextStyle, 'linkTextStyle')}>{schemeInformation[BEAUTY].name}</div>
      </a>
      <a className={rowStyle} href={schemeInformation[AXIS_CLINICAL].url} target="_blank" rel="noopener noreferrer">
        <img src={schemeInformation[AXIS_CLINICAL].logo} alt="Insync logo" className={logoStyle} />
        <div className={cx(linkTextStyle, 'linkTextStyle')}>{schemeInformation[AXIS_CLINICAL].name}</div>
      </a>
      <a className={rowStyle} href={schemeInformation[AXIS_AESTHETIC].url} target="_blank" rel="noopener noreferrer">
        <img src={schemeInformation[AXIS_AESTHETIC].logo} alt="Insync logo" className={logoStyle} />
        <div className={cx(linkTextStyle, 'linkTextStyle')}>{schemeInformation[AXIS_AESTHETIC].name}</div>
      </a>
    </div>
  ) : (
    <div className={containerStyle} onClick={() => setOpenNewPolicy(true)}>
      <img src={newPolicyUrl} alt="newPolicy" className={imgStyle} />
      <div className={textStyle}>{quote ? 'New Quote' : 'New Policy'}</div>
    </div>
  );
};
