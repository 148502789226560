import React, { useState } from 'react';
import { css, cx } from 'emotion';
import { Link, withRouter } from 'react-router-dom';
import insyncLogo from '../../assets/insyncLogo.svg';
import quotesUrl from '../../assets/quotesIcon.png';
import menuIcon from '../../assets/hamburger.svg';
import closeIcon from '../../assets/closeIcon.svg';
import arrowUrl from '../../assets/arrow.png';
import policiesUrl from '../../assets/policiesIcon.png';
import benefitsUrl from '../../assets/benefitsUrl.png';
import { useFirebase } from '../Firebase';
import profileImageUrl from '../../assets/profileImage.png';

const headerStyle = css`
  display: none;
  grid-column: 1 / 2;
  grid-row: 1 / 2;
  background: rgba(36, 36, 39, 0.04);
  padding: 1.5rem 1rem;
  @media (max-width: 767px) {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;

const menuHeaderStyle = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 0 2rem 0;
`;

const logoStyle = css`
  height: 2rem;
`;

const menuIconStyle = css`
  height: 0.75rem;
`;

const closeIconStyle = css`
  height: 1rem;
`;

const linksContainer = css`
  flex: 1;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const linkStyle = css`
  color: var(--darkGray);

  font-weight: bold;
  font-size: 0.875rem;
  line-height: 1.5em;
  letter-spacing: 0.02em;
  cursor: pointer;
  display: flex;
  margin-left: 2.5rem;
  &:hover {
    text-decoration: none;
    color: var(--darkGray);
    opacity: 0.8;
  }
`;

const sectionStyle = css`
  cursor: pointer;
  margin-bottom: 2rem;
  justify-content: space-between;
  display: flex;
  flex-direction: row;
  text-decoration: none;
  &:hover {
    text-decoration: none;
  }
`;

const sectionTextStyle = css`
  font-weight: bold;
  font-size: 0.875rem;
  line-height: 1.5em;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: var(--darkGray);
  flex: 1;
  margin-left: 1.5rem;
`;

const nonActiveImgStyle = css`
  filter: grayscale(100%);
`;

const activeStyle = css`
  color: #242427;
  &:after {
    content: '';
    display: block;
    width: 4px;
    background: var(--primaryBrand);
    border-radius: 3px;
  }
`;

const flexContainer = css`
  display: flex;
  align-items: center;
  &:hover {
    text-decoration: none;
  }
`;

const bottomMarginStyle = css`
  margin-bottom: 1rem;
`;

const arrowStyle = css`
  height: 1rem;
  margin-left: 0.25rem;
`;

const menuStyle = css`
  background-color: white;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
`;

const profileContainer = css`
  margin-top: 0.6875rem;
`;

const imageStyle = css`
  height: 1.5rem;
  margin-right: 1.125rem;
`;

const logoutButton = css`
  font-weight: bold;
  font-size: 0.875rem;
  line-height: 1.5em;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: var(--darkGray);
  margin-left: 2.5rem;
`;

const usernameStyle = css`
  font-weight: bold;
  font-size: 0.875rem;
  line-height: 1.5em;
  letter-spacing: 0.02em;
  color: var(--darkGray);
  text-decoration: none;
  cursor: pointer;
  &:hover {
    text-decoration: none;
  }
  &:active {
    text-decoration: none;
  }
  &:focus {
    text-decoration: none;
    outline: none;
  }
`;

const activeSubCategory = css`
  color: var(--primaryBrand);
  &:hover {
    text-decoration: none;
    color: var(--primaryBrand);
    opacity: 0.8;
  }
`;

export const Header = withRouter(props => {
  const { location } = props;
  const { pathname } = location;
  const [showMenu, setShowMenu] = useState(false);
  const { doSignOut } = useFirebase();

  const isPolicyActive = () => pathname.startsWith('/policies') || pathname === '/';

  const isQuoteActive = () => pathname === '/quotes';

  const isBenefitActive = () => pathname === '/benefits';

  const isCommonQuestionsActive = () => pathname === '/commonQuestions';

  const isContactUsActive = () => pathname === '/contactUs';

  return (
    <header className={headerStyle}>
      <Link to="/" onClick={() => window.scrollTo(0, 0)}>
        <img src={insyncLogo} alt="logo" className={logoStyle} />
      </Link>
      <img src={menuIcon} alt="menu" className={menuIconStyle} onClick={() => setShowMenu(true)} />
      {showMenu && (
        <div className={menuStyle}>
          <div className={linksContainer}>
            <div className={menuHeaderStyle}>
              <Link
                to="/"
                onClick={() => {
                  window.scrollTo(0, 0);
                  setShowMenu(false);
                }}
              >
                <img src={insyncLogo} alt="logo" className={logoStyle} />
              </Link>
              <img src={closeIcon} alt="close" className={closeIconStyle} onClick={() => setShowMenu(false)} />
            </div>
            <div>
              <Link
                className={cx(sectionStyle, isPolicyActive() ? activeStyle : '')}
                to="/policies"
                onClick={() => {
                  window.scrollTo(0, 0);
                  setShowMenu(false);
                }}
              >
                <img src={policiesUrl} alt="policies" className={isPolicyActive() ? '' : nonActiveImgStyle} />
                <div className={sectionTextStyle}>My policies</div>
              </Link>
              <Link
                className={cx(sectionStyle, isQuoteActive() ? activeStyle : '')}
                to="/quotes"
                onClick={() => setShowMenu(false)}
              >
                <img src={quotesUrl} alt="quotes" className={isQuoteActive() ? '' : nonActiveImgStyle} />
                <div className={sectionTextStyle}>My quotes</div>
              </Link>
              <Link
                className={cx(sectionStyle, isBenefitActive() ? activeStyle : '')}
                to="/benefits"
                onClick={() => {
                  window.scrollTo(0, 0);
                  setShowMenu(false);
                }}
              >
                <img src={benefitsUrl} alt="benefits" className={isBenefitActive() ? '' : nonActiveImgStyle} />
                <div className={sectionTextStyle}>Insync benefits</div>
              </Link>
            </div>
            <div>
              <Link
                className={cx(linkStyle, bottomMarginStyle, isCommonQuestionsActive() ? activeSubCategory : '')}
                to="/commonQuestions"
                onClick={() => {
                  window.scrollTo(0, 0);
                  setShowMenu(false);
                }}
              >
                Common Questions
              </Link>
              <Link
                className={cx(linkStyle, bottomMarginStyle, isContactUsActive() ? activeSubCategory : '')}
                to="/contactUs"
                onClick={() => {
                  window.scrollTo(0, 0);
                  setShowMenu(false);
                }}
              >
                Contact Us
              </Link>
              <a
                className={cx(flexContainer, bottomMarginStyle)}
                href="https://blog.insyncinsurance.co.uk/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className={linkStyle}>Blog</div>
                <img src={arrowUrl} alt="arrow" className={arrowStyle} />
              </a>
              <a
                className={cx(flexContainer, bottomMarginStyle)}
                href="https://insyncinsurance.formstack.com/forms/portalfeedback"
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className={linkStyle}>Leave Feedback</div>
                <img src={arrowUrl} alt="arrow" className={arrowStyle} />
              </a>
            </div>
            <div className={profileContainer}>
              <Link
                className={cx(flexContainer, bottomMarginStyle)}
                to="/myAccount"
                onClick={() => {
                  window.scrollTo(0, 0);
                  setShowMenu(false);
                }}
              >
                <img src={profileImageUrl} className={imageStyle} alt="profileImage" />
                <div className={usernameStyle}>My Profile</div>
              </Link>
              <div className={logoutButton} onClick={doSignOut}>
                Sign out
              </div>
            </div>
          </div>
        </div>
      )}
    </header>
  );
});
