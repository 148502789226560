import React, { useState } from 'react';
import { css } from 'emotion';
import { Accordion } from './Accordion';
import { Formik, useFormikContext } from 'formik';
import { MarketingCheckboxes } from './MarketingCheckboxes';
import { updateMarketingOptions } from '../../api/api';
import { useFirebase } from '../Firebase';
import { CustomButton } from './CustomButton';
import * as Yup from 'yup';
import { SwitchField } from './SwitchField';
import { useFormikField } from '../../utils/use-formik-field';
import { Loader } from './Loader';

const buttonContainer = css`
  display: flex;
  flex: 1;
  justify-content: flex-end;
  width: 100%;
  padding-top: 0.5rem;
`;
const sectionTextStyle = css`
  text-transform: uppercase;
  font-family: Poppins, sans-serif;
  font-weight: bold;
  font-size: 0.875rem;
  line-height: 1.3125rem;
  color: var(--dark);
`;

const dividerStyle = css`
  border: 1px solid var(--gray);
  margin-right: -1rem;
`;
export const MarketingAccordion = props => {
  const { show, onClick, userSettings, setUserSettings, isLoading } = props;
  const firebase = useFirebase();
  const [changed, setChanged] = useState(false);
  return (
    <Accordion
      title="Marketing options"
      show={show}
      headerOnCLick={onClick}
      body={
        <div>
          {isLoading && <Loader />}
          {!isLoading && (
            <Formik initialValues={userSettings} onSubmit={updateMarketingOptions} validationSchema={Yup.object()}>
              {formik => (
                <>
                  <div className={sectionTextStyle}>Please choose your marketing settings</div>
                  <SwitchField
                    name="marketing.sms"
                    id="marketing.sms"
                    label="Opt In SMS marketing"
                    setChanged={setChanged}
                  />
                  <div className={dividerStyle} />
                  <SwitchField
                    name="marketing.email"
                    id="marketing.email"
                    label="Opt In Email marketing"
                    setChanged={setChanged}
                  />
                  <div className={dividerStyle} />
                  <SwitchField
                    name="marketing.phone"
                    id="marketing.phone"
                    label="Opt In Phone marketing"
                    setChanged={setChanged}
                  />
                  {/* <MarketingCheckboxes setChanged={setChanged} /> */}
                  <div className={buttonContainer}>
                    <CustomButton
                      label="Save"
                      disabled={!changed}
                      secondary
                      handleClick={() => {
                        setUserSettings(formik.values);
                        updateMarketingOptions(firebase, formik);
                        setChanged(false);
                      }}
                    />
                  </div>
                </>
              )}
            </Formik>
          )}
        </div>
      }
    />
  );
};
