import React from 'react';
import { css, cx } from 'emotion';
import get from 'lodash/get';
import { schemeInformation } from '../../constants/products';
import insyncLogo from '../../assets/insyncLogoSecundary.png';
import errorIcon from '../../assets/errorMessageIcon.png';

const containerStyle = css`
  display: flex;
  padding: 1rem;
  border-radius: 8px;
  background: white;
  min-width: 23.125rem;
  max-width: 23.125rem;
  min-height: 11.125rem;
  margin-bottom: 1.5rem;
  text-decoration: none;
  &:hover {
    text-decoration: none;
    box-shadow: 0 8px 40px rgba(64, 64, 66, 0.08);
    cursor: not-allowed;
  }
  @media (max-width: 767px) {
    min-width: 100%;
  }
`;

const logoStyle = css`
  height: 1.5rem;
  align-self: flex-start;
  margin-right: 0.75rem;
`;

const infoContainerStyle = css`
  display: flex;
  flex-direction: column;
`;

const productTitleStyle = css`
  font-weight: bold;
  font-size: 1rem;
  line-height: 1.5em;
  letter-spacing: -0.03em;
  color: var(--dark);
`;
const rowStyle = css`
  margin-bottom: 1rem;
`;

const inlineBlock = css`
  display: inline-block;
`;

const categoryNameStyle = css`
  font-weight: bold;
  font-size: 0.75rem;
  line-height: 0.875rem;
  letter-spacing: -0.03em;
  color: var(--darkGray);
  margin-right: 0.75rem;
`;

const quoteNoStyle = css`
  font-weight: bold;
  font-size: 1.5rem;
  line-height: 1.5em;
  letter-spacing: -0.03em;
  color: var(--dark);
`;

const errorInfoStyle = css`
  font-weight: bold;
  font-size: 0.8rem;
  line-height: 0.9rem;
  letter-spacing: -0.03em;
  color: var(--error);
  margin-top: auto;
  padding-bottom: 0.3rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const iconStyle = css`
  display: block;
  height: 1.5rem;
  margin-right: 8px;
`;

const cancelledStyle = css`
  height: 100%;
  opacity: 0.5;
  display: flex;
  flex: 1;
  filter: grayscale(60%);
  &:before {
    content: '';
    display: block;
    width: 4px;
    background: var(--darkGray);
    border-radius: 3px;
    margin-right: 1.5rem;
  }
`;

export const ErrorQuoteCard = props => {
  const { quote } = props;
  const { productCode, quoteRef } = quote;

  return (
    <div className={containerStyle}>
      <div className={cancelledStyle}>
        <div className={infoContainerStyle}>
          <div>
            <div className={rowStyle}>
              <img
                src={get(schemeInformation[productCode], 'logo') || insyncLogo}
                alt="insyncLogo"
                className={cx(logoStyle, inlineBlock)}
              />
              <div className={cx(productTitleStyle, inlineBlock)}>{get(schemeInformation[productCode], 'name')}</div>
            </div>
            <div className={rowStyle}>
              <div className={cx(categoryNameStyle, inlineBlock)}>Quote Reference</div>
              <div className={quoteNoStyle}>{quoteRef}</div>
            </div>
          </div>
          <div className={cx(errorInfoStyle)}>
            <img src={errorIcon} className={iconStyle} alt="error icon" />
            <div>
              An error occured while fetching this quote
              <br /> Please try again later
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
