import React from 'react';
import { css } from 'emotion';

const containerStyle = css`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
  padding: 0.5rem 1rem 1rem 1rem;
  @media (min-width: 768px) {
    width: 100%;
    max-width: 44.375rem;
    margin: 0 auto;
  }
`;

export const Container = props => {
  const { children } = props;
  return <div className={containerStyle}>{children}</div>;
};
