import React from 'react';
import { css, cx } from 'emotion';
import { useFormikContext } from 'formik';
import checkUrl from '../../assets/check.svg';
import { useFormikField } from '../../utils/use-formik-field';

const wrapperStyle = css`
  display: flex;
  width: 100%;
  flex-direction: column;
  margin: 1.5625rem 0;
`;

const containerStyle = css`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  color: #404042;
  font-weight: bold;
  font-family: Lato, sans-serif;
  font-size: 1rem;
  line-height: 1.5em;
`;
const optionContainerStyle = css`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
  color: #404042;
  font-weight: bold;
  font-family: Lato, sans-serif;
  font-size: 1rem;
  line-height: 1.5em;
`;

const checkboxContainer = css`
  border-radius: 2px;
  :focus {
    outline: none !important;
  }
  :hover {
    background-color: #efefef;
  }
`;

const onStyle = css`
  background-color: #ff7300;
  border-radius: 2px;
  width: 1.5rem;
  height: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const offStyle = css`
  border: 2px solid #e5e5e5;
  border-radius: 2px;
  width: 1.5rem;
  height: 1.5rem;
`;

const iconStyle = css`
  width: 1rem;
  height: 1rem;
`;
const buttonsContainer = css`
  display: flex;
  gap: 1rem;
`;

const labelStyle = css`
  font-family: Lato, sans-serif;
  font-size: 1.2rem;
  line-height: 1.0625rem;
`;

const CheckboxFieldComponent = props => {
  const { value, setFieldValue, label, customWrapperStyle, id } = props;
  return (
    <div className={cx(wrapperStyle, customWrapperStyle)} id={id}>
      <div className={containerStyle}>
        <p className={labelStyle}>Marketing Opt In</p>
        <div className={buttonsContainer}>
          <div className={optionContainerStyle}>
            <p>Email</p>
            <div
              role="button"
              tabIndex={0}
              className={checkboxContainer}
              onClick={() => setFieldValue('marketing.email', !value.email)}
              onKeyDown={() => setFieldValue('marketing.email', !value.email)}
            >
              <div className={value.email ? onStyle : offStyle}>
                {value && <img src={checkUrl} alt="check" className={iconStyle} />}
              </div>
            </div>
          </div>
          <div className={optionContainerStyle}>
            <p>SMS</p>
            <div
              role="button"
              tabIndex={0}
              className={checkboxContainer}
              onClick={() => setFieldValue('marketing.sms', !value.sms)}
              onKeyDown={() => setFieldValue('marketing.sms', !value.sms)}
            >
              <div className={value.sms ? onStyle : offStyle}>
                {value && <img src={checkUrl} alt="check" className={iconStyle} />}
              </div>
            </div>
          </div>
          <div className={optionContainerStyle}>
            <p>Phone</p>
            <div
              role="button"
              tabIndex={0}
              className={checkboxContainer}
              onClick={() => setFieldValue('marketing.phone', !value.phone)}
              onKeyDown={() => setFieldValue('marketing.phone', !value.phone)}
            >
              <div className={value.phone ? onStyle : offStyle}>
                {value && <img src={checkUrl} alt="check" className={iconStyle} />}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const MarketingCheckboxes = props => {
  const { label, name, customWrapperStyle, id, setChanged } = props;

  const { isInErrorState, errorMessage, touched, value } = useFormikField('marketing');
  const { setFieldValue } = useFormikContext();
  return (
    <CheckboxFieldComponent
      label={label}
      name={name}
      value={value}
      isInErrorState={isInErrorState}
      errorMessage={errorMessage}
      touched={touched}
      setFieldValue={(key, val) => {
        setChanged(true);
        setFieldValue(key, val);
      }}
      customWrapperStyle={customWrapperStyle}
      id={id}
    />
  );
};
