import React from 'react';
import { css } from 'emotion';

const containerStyle = css`
  height: 13.625rem;
  width: 100%;
  background: rgba(255, 255, 255, 0.5);
  color: var(--darkGray);
  text-align: center;

  font-weight: bold;
  font-size: 1.125rem;
`;

export const SalesBanner = () => <div className={containerStyle}>Sales Banner</div>;
