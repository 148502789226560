import React, { Fragment, useRef, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { css, cx } from 'emotion';
import moment from 'moment';
import insyncLogo from '../../assets/insyncLogoSecundary.png';
import { AXIS_AESTHETIC, schemeInformation } from '../../constants/products';
import { lookupStore } from '../../store/lookup-store';
import { Loader } from '../components/Loader';
import { AxisAestheticTreatmentsDisplay } from '../components/AxisAestheticTreatmentsDisplay';
import { DocumentQuoteContainer } from './DocumentQuoteContainer';
import { QuoteButtonsContainer } from './QuoteButtonsContainer';
import { useUserQuote } from '../../api/api';

const containerStyle = css`
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  background: white;
  margin-right: 2rem;
  margin-left: 2rem;
  margin-bottom: 3rem;
  @media (max-width: 767px) {
    margin-right: 1rem;
    margin-left: 1rem;
    border-radius: 8px;
  }
`;

const headerContainer = color => css`
  background: ${color};
  padding: 1.5rem 1.5rem 2rem 1.5rem;
  @media (max-width: 767px) {
    border-radius: 8px;
    padding: 1.25rem 1.5rem 2rem 1.5rem;
  }
`;

const categoryText = css`
  font-weight: bold;
  font-size: 1rem;
  line-height: 1.1875rem;
  letter-spacing: -0.03em;
  color: var(--white);
  opacity: 0.64;
`;

const rowStyle = css`
  margin-bottom: 1rem;
`;

const insuranceNameStyle = css`
  font-weight: bold;
  font-size: 1.125rem;
  line-height: 1.375rem;
  letter-spacing: -0.03em;
  color: var(--white);
  margin-left: 0.75rem;
`;

const inlineBlock = css`
  display: inline-block;
`;

const quoteNumberStyle = css`
  font-weight: bold;
  font-size: 2rem;
  line-height: 2.375rem;
  letter-spacing: -0.03em;
  color: var(--white);
`;

const bottomRow = css`
  margin-top: 0.25rem;
  display: flex;
  justify-content: space-between;
`;

const logoStyle = css`
  height: 2.5rem;
  @media (max-width: 767px) {
    display: none;
  }
`;

const infoRowStyle = css`
  display: flex;
  border-bottom: 0.5px solid var(--gray);
  @media (max-width: 767px) {
    flex-direction: column;
  }
`;

const constantInfo = css`
  flex: 1;
  padding: 1.5rem;
  @media (max-width: 767px) {
    padding: 1rem;
  }
`;

const specificInfo = color => css`
  flex: 1;
  padding: 1.5rem;
  background: ${color || undefined};
  @media (max-width: 767px) {
    padding: 1rem;
  }
`;

const categoryStyle = css`
  font-weight: bold;
  font-size: 1rem;
  line-height: 1.1875rem;
  letter-spacing: -0.03em;
  color: var(--dark);
  opacity: 0.64;
  margin-bottom: 0.25rem;
`;
const valueStyle = css`
  font-weight: bold;
  font-size: 1rem;
  line-height: 1.1875rem;
  letter-spacing: -0.03em;
  color: var(--dark);
  margin-bottom: 1rem;
`;

const tableCategory = css`
  min-width: 9.5rem;

  font-weight: bold;
  font-size: 1rem;
  letter-spacing: -0.03em;
  color: var(--dark);
  opacity: 0.64;
  padding-bottom: 1rem;
  @media (max-width: 767px) {
    min-width: 5.3125rem;
    font-size: 0.75rem;
    line-height: 1.17rem;
  }
`;

const tableValue = css`
  font-weight: bold;
  font-size: 1rem;
  letter-spacing: -0.03em;
  color: var(--dark);
  padding-bottom: 1rem;
  @media (max-width: 767px) {
    font-weight: normal;
  }
`;

const tableStyle = css`
  max-width: 100%;
`;

export const MyQuote = withRouter(props => {
  const { quoteRef } = props.match.params;
  const [treatmentsExpanded, setTreatmentsExpanded] = useState(false);
  const quoteDescriptionRef = useRef(null);
  const [isDownloadLoading, setIsDownloadLoading] = useState(false);
  const { isLoading, data: quoteState } = useUserQuote(quoteRef);

  const loaderDivHeight = css`
    margin-top: 1rem;
    height: ${quoteDescriptionRef?.current?.offsetHeight - 16}px;
  `;

  return quoteState ? (
    <div className={containerStyle}>
      <div className={headerContainer(schemeInformation[quoteState.productCode]?.headerColor || '#ffc600')}>
        <div className={rowStyle}>
          <div className={cx(categoryText, inlineBlock)}>Insurance</div>
          <div className={cx(insuranceNameStyle, inlineBlock)}>{schemeInformation[quoteState.productCode].name}</div>
        </div>
        <div className={categoryText}>Quote Reference</div>
        <div className={bottomRow}>
          <div className={quoteNumberStyle}>{quoteState.quoteRef}</div>
          <img src={insyncLogo} alt="insyncLogo" className={logoStyle} />
        </div>
      </div>
      <div>
        {isLoading || isDownloadLoading ? (
          <div className={loaderDivHeight}>
            <Loader message={isDownloadLoading ? 'Downloading your quote document...' : ''} />
          </div>
        ) : (
          <div ref={quoteDescriptionRef} className={infoRowStyle}>
            <div className={constantInfo}>
              <table className={tableStyle}>
                <tbody>
                  <tr>
                    <td className={tableCategory}>Start Date</td>
                    <td className={tableValue}>{moment(quoteState.coverStartDate).format('DD/MM/YYYY')}</td>
                  </tr>
                  <tr>
                    <td className={tableCategory}>End Date</td>
                    <td className={tableValue}>{moment(quoteState.coverEndDate).format('DD/MM/YYYY')}</td>
                  </tr>
                  <tr>
                    <td className={tableCategory}>Holder Name</td>
                    <td className={tableValue}>
                      {`${lookupStore.getTitleNameFromPolicyholder(quoteState.policyHolder)} ${
                        quoteState.policyHolder.firstName
                      } ${quoteState.policyHolder.lastName}`}
                    </td>
                  </tr>
                  <tr>
                    <td className={tableCategory}>Holder Email</td>
                    <td className={tableValue}>{quoteState.policyHolder.emailAddress}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className={specificInfo(schemeInformation[quoteState.productCode].specificInfoColor)}>
              {Object.entries(schemeInformation[quoteState.productCode].specialCategoriesQuote).map(([key, value]) => (
                <Fragment key={key}>
                  <div className={categoryStyle}>{key}</div>
                  <div className={valueStyle}>{value(quoteState)}</div>
                </Fragment>
              ))}
              {quoteState.productCode === AXIS_AESTHETIC && (
                <AxisAestheticTreatmentsDisplay
                  treatmentsExpanded={treatmentsExpanded}
                  setTreatmentsExpanded={setTreatmentsExpanded}
                  quoteState={quoteState}
                />
              )}
            </div>
          </div>
        )}
      </div>
      <DocumentQuoteContainer setIsLoading={setIsDownloadLoading} quoteState={quoteState} />
      <QuoteButtonsContainer quoteState={quoteState} />
    </div>
  ) : (
    <Loader />
  );
});
