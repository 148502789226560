import React from 'react';
import { css } from 'emotion';
import { Link } from 'react-router-dom';
import profileImageUrl from '../../assets/profileImage.png';
import referImageUrl from '../../assets/referAFriend.png';
import { Button } from './Button';
import { useFirebase } from '../Firebase';

const rightSideBar = css`
  grid-column: 3 / 4;
  grid-row: 1 / 2;
  background: white;
  @media (max-width: 767px) {
    grid-column: 1 / 2;
    grid-row: 3 / 4;
  }
`;

const stickySideBar = css`
  position: sticky;
  top: 2rem;
  height: 100%;
  max-height: 100vh;
`;

const profileContainer = css`
  background: rgba(36, 36, 39, 0.04);
  border-radius: 8px;
  margin: 2rem 2rem 7.25rem 2rem;
  padding: 1.8125rem 1.5rem;
  display: flex;
  color: var(--dark);
  cursor: pointer;
  &:hover {
    color: var(--dark);
    text-decoration: none;
  }
  @media (max-width: 767px) {
    display: none;
  }
`;

const nameStyle = css`
  font-weight: bold;

  font-size: 1.25rem;
  line-height: 1.5rem;
  letter-spacing: -0.03em;
  position: relative;

  &:hover:after {
    width: 100%;
    opacity: 1;
  }

  &:after {
    content: '';
    position: absolute;
    bottom: -3px;
    height: 2px;
    left: 50%;
    width: 0;
    opacity: 0;
    background: var(--primaryBrand);
    transform: translateX(-50%);
    transition: width 0.25s, opacity 0.25s;
  }
`;

const logoutStyle = css`
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.5em;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: var(--primaryBrand);
  margin-top: 0.25rem;
  cursor: pointer;
  position: relative;
  display: inline-block;
  &:hover:after {
    width: 100%;
    opacity: 1;
  }

  &:after {
    content: '';
    position: absolute;
    bottom: -3px;
    height: 2px;
    left: 50%;
    width: 0;
    opacity: 0;
    background: var(--primaryBrand);
    transform: translateX(-50%);
    transition: width 0.25s, opacity 0.25s;
  }
`;

const imageStyle = css`
  margin-right: 1rem;
  height: 3.375rem;
`;

const referContainer = css`
  background: rgba(36, 36, 39, 0.04);
  padding: 1.5rem;
  margin: 0 2rem 3.6875rem 2rem;
  border-radius: 8px;
  text-align: center;
  @media (max-width: 767px) {
    margin-top: 7rem;
  }
`;

const referTextStyle = css`
  font-weight: 900;
  font-size: 1.125rem;
  line-height: 1.5em;
  text-align: center;
  letter-spacing: -0.03em;
  margin-bottom: 1.5rem;
`;

const refereImageStyle = css`
  margin-top: -5.5rem;
  margin-bottom: 1.5rem;
  height: 8.5rem;
`;

const linkStyle = css`
  color: white;
  &:hover {
    color: white;
    text-decoration: none;
  }
`;

export const RightSidebar = () => {
  const { doSignOut } = useFirebase();

  return (
    <aside className={rightSideBar}>
      <div className={stickySideBar}>
        <Link className={profileContainer} to="/myAccount" onClick={() => window.scrollTo(0, 0)}>
          <img src={profileImageUrl} className={imageStyle} alt="profileImage" />
          <div>
            <div className={nameStyle}>My Profile</div>
            <div className={logoutStyle} onClick={doSignOut}>
              Sign out
            </div>
          </div>
        </Link>
        <div className={referContainer}>
          <img src={referImageUrl} alt="refer a friend" className={refereImageStyle} />
          <div className={referTextStyle}>Refer a friend and get a £20 Amazon Voucher. Sound cool?</div>
          <a
            href="https://insyncinsurance.formstack.com/forms/portalreferafriend"
            target="_blank"
            rel="noopener noreferrer"
            className={linkStyle}
          >
            <Button label="Refer a friend" />
          </a>
        </div>
      </div>
    </aside>
  );
};
