import React from 'react';
import { css, cx, keyframes } from 'emotion';

const buttonStyle = css`
  text-align: center;
  padding: 0.5rem 1.5rem;

  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1.5em;
  letter-spacing: 0.02em;
  text-transform: uppercase;

  cursor: pointer;
  @media (max-width: 767px) {
    width: 100%;
  }
`;

const anim1 = keyframes`
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }
  40% {
    box-shadow: 0 2.5em 0 0;
  }
`;
const loaderStyle = css`
  top: -1.8rem;
  border-radius: 50%;
  position: relative;
  width: 0.75rem;
  height: 0.75rem;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation: ${anim1} 1.8s infinite ease-in-out;
  animation: ${anim1} 1.8s infinite ease-in-out;
  :before,
  :after {
    border-radius: 50%;
    width: 0.75rem;
    height: 0.75rem;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation: ${anim1} 1.8s infinite ease-in-out;
    animation: ${anim1} 1.8s infinite ease-in-out;
  }
  color: #ffffff;
  font-size: 0.75rem;
  margin: 0.5rem auto;
  text-indent: -9999em;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
  :before,
  :after {
    content: '';
    position: absolute;
    top: 0;
  }
  :after {
    left: 1.25rem;
  }
  :before {
    left: -1.25rem;
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
  }
`;

const primaryButton = css`
  background: var(--primaryBrand);
  color: var(--white);
  border: 2px solid transparent;
  &:hover {
    background: #ff8826;
  }
`;

const secondaryButton = css`
  background: white;
  border: 2px solid var(--primaryBrand);
  color: var(--primaryBrand);
  &:hover {
    color: #ff8826;
    border-color: #ff8826;
  }
`;

const benefitButton = css`
  background: #2ee7b3;
  color: var(--white);
`;

export const Button = props => {
  const { label, onClick, secondary, benefits, loading } = props;
  return (
    <div
      className={
        secondary
          ? cx(buttonStyle, secondaryButton)
          : benefits
          ? cx(buttonStyle, benefitButton)
          : cx(buttonStyle, primaryButton)
      }
      onClick={onClick}
    >
      {!loading ? label : ''}
      {loading && <div className={loaderStyle} />}
    </div>
  );
};
