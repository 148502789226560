import React from 'react';
import { css, cx } from 'emotion';
import { Link, withRouter } from 'react-router-dom';
import insyncLogo from '../../assets/insyncLogo.svg';
import arrowUrl from '../../assets/arrowUp.svg';
import policiesUrl from '../../assets/policiesIcon.png';
import quotesUrl from '../../assets/quotesIcon.png';
import benefitsUrl from '../../assets/benefitsUrl.png';
import { userStore } from '../../store/user-store';

const leftSideBar = css`
  grid-column: 1 / 2;
  grid-row: 1 / 2;
  background: white;
  @media (max-width: 767px) {
    display: none;
  }
`;

const stickySideBar = css`
  position: sticky;
  top: 0;
  height: 100%;
  max-height: 100vh;
  display: flex;
  flex-direction: column;
`;

const logoStyle = css`
  height: 2.5rem;
  margin: 2rem;
  align-self: flex-start;
`;

const linksContainer = css`
  flex: 1;
  margin: 2rem 0 1rem 2rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const linkStyle = css`
  color: var(--darkGray);

  font-weight: bold;
  font-size: 0.875rem;
  line-height: 1.5em;
  letter-spacing: 0.02em;
  cursor: pointer;
  display: flex;
  &:hover {
    text-decoration: none;
    color: var(--primaryBrand);
  }
`;

const sectionStyle = css`
  cursor: pointer;
  margin-bottom: 2rem;
  justify-content: space-between;
  display: flex;
  flex-direction: row;
  text-decoration: none;
  color: var(--darkGray);
  filter: grayscale(100%);
  &:hover {
    text-decoration: none;
    color: #242427;
    filter: grayscale(0);
  }
`;

const sectionTextStyle = css`
  font-weight: bold;
  font-size: 0.875rem;
  line-height: 1.5em;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  flex: 1;
  margin-left: 1.5rem;
`;

const activeStyle = css`
  color: #242427;
  filter: grayscale(0);
  &:after {
    content: '';
    display: block;
    width: 4px;
    background: var(--primaryBrand);
    border-radius: 3px;
  }
`;

const flexLinkContainer = css`
  display: flex;
  align-items: center;
  filter: grayscale(100%);
  color: var(--darkGray);

  font-weight: bold;
  font-size: 0.875rem;
  line-height: 1.5em;
  letter-spacing: 0.02em;
  cursor: pointer;
  &:hover {
    text-decoration: none;
    filter: grayscale(0);
    color: var(--primaryBrand);
  }
`;

const bottomMarginStyle = css`
  margin-bottom: 1rem;
`;

const arrowStyle = css`
  height: 1rem;
  margin-left: 0.25rem;
`;

const activeSubCategory = css`
  color: var(--primaryBrand);
  &:hover {
    text-decoration: none;
    color: var(--primaryBrand);
    opacity: 0.8;
  }
`;

const imgStyle = css`
  height: 1.5rem;
`;

export const LeftSidebar = withRouter(props => {
  const { location } = props;
  const { pathname } = location;

  const isPolicyActive = () => pathname.startsWith('/policies') || pathname === '/';

  const isQuoteActive = () => pathname.startsWith('/quotes');

  const isBenefitActive = () => pathname === '/benefits';

  const isCommonQuestionsActive = () => pathname === '/commonQuestions';

  const isContactUsActive = () => pathname === '/contactUs';

  return (
    <aside className={leftSideBar}>
      <div className={stickySideBar}>
        <Link to="/" onClick={() => window.scrollTo(0, 0)}>
          <img src={insyncLogo} alt="logo" className={logoStyle} />
        </Link>
        <div className={linksContainer}>
          <div>
            <Link
              className={cx(sectionStyle, isPolicyActive() ? activeStyle : '')}
              to="/policies"
              onClick={() => window.scrollTo(0, 0)}
            >
              <img src={policiesUrl} alt="policies" className={imgStyle} />
              <div className={sectionTextStyle}>My policies</div>
            </Link>
            <Link className={cx(sectionStyle, isQuoteActive() ? activeStyle : '')} to="/quotes">
              <img src={quotesUrl} alt="quotes" className={imgStyle} />
              <div className={sectionTextStyle}>My quotes</div>
            </Link>
            {userStore.isInsyncCustomer && (
              <Link
                className={cx(sectionStyle, isBenefitActive() ? activeStyle : '')}
                to="/benefits"
                onClick={() => window.scrollTo(0, 0)}
              >
                <img src={benefitsUrl} alt="benefits" className={imgStyle} />
                <div className={sectionTextStyle}>Insync benefits</div>
              </Link>
            )}
          </div>
          <div>
            <Link
              className={cx(linkStyle, bottomMarginStyle, isCommonQuestionsActive() ? activeSubCategory : '')}
              to="/commonQuestions"
              onClick={() => window.scrollTo(0, 0)}
            >
              Common Questions
            </Link>
            <Link
              className={cx(linkStyle, bottomMarginStyle, isContactUsActive() ? activeSubCategory : '')}
              to="/contactUs"
              onClick={() => window.scrollTo(0, 0)}
            >
              Contact Us
            </Link>
            <a
              className={cx(flexLinkContainer, bottomMarginStyle)}
              href="https://blog.insyncinsurance.co.uk/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <div>Blog</div>
              <img src={arrowUrl} alt="arrow" className={arrowStyle} />
            </a>
            <a
              className={cx(flexLinkContainer, bottomMarginStyle)}
              href="https://insyncinsurance.formstack.com/forms/portalfeedback"
              target="_blank"
              rel="noopener noreferrer"
            >
              <div>Leave Feedback</div>
              <img src={arrowUrl} alt="arrow" className={arrowStyle} />
            </a>
          </div>
        </div>
      </div>
    </aside>
  );
});
